var site = site || {};
var generic = generic || {};

site.centerModal = function($modal) {
  $modal.css({marginTop: ($(window).height() - $modal.outerHeight()) / 2});
  if (site.client.isMobile) {
    $modal.css({marginLeft: 'auto'});
  } else {
    $modal.css({marginLeft: ($(window).width() - $modal.outerWidth()) / 2});
  }
};

site.getLangsFromCountryLink = function($link) {
  var language_list = new Array();
  var language_abbrs = $link.data('languages').split(','); // get country's supported lang abbrs
  var language_names = $link.data('languagenames').split(','); // and names
  var i;
  for (i = 0; i < language_abbrs.length; i++) {
    language_list.push('<li data-language="' + language_abbrs[i] + '">' + language_names[i] + '</li>');
  }

  for (i = 0; i < language_list.length; i++) { // make sure en is always first
    if (language_list[i].substr('data-language="en"') && i > 0) {
      language_list.splice(0, 0, language_list.splice(i, 1));
    }
  }

  return language_list;
};

site.setupLanguageMenu = function($container, prefCountry, prefLanguage) { // takes the list of countries and supported languages and creates separate country and language links
  $container.find('.country-list li').each(function() {
    var $link = $(this).find('a');
    if ($link.data('offsite')) {
      $container.find('.country-list-offsite').append($(this)); // if country is offsite add it to the separate offsite country list
    } else {
      if ($link.data('country').toLowerCase() === prefCountry) { // if this is the user's selected country
        $('#mobileCountryLink').html($link.html()); // create the mobile link, which looks like this flag and country name
        $('#mobileCountryLink').data('countryname', $link.data('countryname'));
        $('#countryPickerLink').html(prefCountry.toUpperCase()); // set the dropdown country to be this country's code
        $link.addClass('current');
        $link.addClass('selected');
        if (!prefLanguage) {
          prefLanguage = $link.data('languages').split(',')[0]; // set preferred language to country's default if not defined
        }
        $container.find('.language-list').html(site.getLangsFromCountryLink($link).join(''));
        $container.find('.language-list li').removeClass('current-lang');
        $container.find('.language-list li').each(function() {
          if ($(this).data('language') === prefLanguage) {
            $(this).addClass('current-lang');
          }
        });
      }
    }
  });
};

site.getAltLanguage = function(prefLanguage, supportedLanguages) {
  var langSupported = false;
  for (var i = 0; i < supportedLanguages.length; i++) {
    if (supportedLanguages[i] === prefLanguage) {
      langSupported = true;
    }
  }
  if (!langSupported) {
    prefLanguage = supportedLanguages[0];
  }
  return prefLanguage;
};

site.setLocaleFromUrl = function() {
  var locale = '';
  var country, languages;
  var validPaths = [];
  var localeMatch = document.cookie.match(new RegExp('(?:^|; )LOCALE=([^;]*)'));
  if (localeMatch && localeMatch.length !== 0) {
    locale = localeMatch[1];
  }
  var countryLink = $('#localeInterstitial a.country-link');
  countryLink.each(function() {
    country = $(this).data('country').toLowerCase();
    languages = $(this).data('languages').split(',');
    for (var i = 0; i < languages.length; i++) {
      validPaths.push('/' + country + '/' + languages[i].toLowerCase());
    }
  });
  var path = window.location.pathname;
  var pathSub = path.substr(0, 6);
  // EMEAFEATUR-10084 - Redirect Switzerland(Italian) users to Switzerland(English)
  if ((locale && locale === 'it_CH') || (pathSub === '/ch/it')) {
    pathSub = '/ch/en';
  }
  if (pathSub) {
    if (validPaths.includes(pathSub)) {
      var pathArray = pathSub.split('/');
      var pathLocale = pathArray[2] + '_' + pathArray[1].toUpperCase();
      if (locale === '' || (locale !== pathLocale)) {
        locale = pathLocale;
        document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        generic.cookie('LOCALE', pathLocale, {path: '/'});
        //EMEAFEATUR-9732 - Reload window once cookie is altered based on url
        //Else it fetches the wrong config in perlgem pages
        location.reload();
      }
    }
  }
};

site.changeLocale = function(country, lang) {
  var postfixes = {};
  var domain = (window.location.href.indexOf('elcdev.net') !== -1) ? '' : '.aveda.eu';
  postfixes[lang] = lang;
  postfixes[country] = country.toLowerCase();

  var locale_cookie = lang + '_' + country.toUpperCase();
  // only switch if not already in requested language
  if (locale_cookie !== generic.cookie('LOCALE')) { // users have selected a different locale than their current one
    var path = '';
    if (generic.cookie('LOCALE')) {
      path = window.location.pathname.replace(/^\/[a-z]{2}\/[a-z]{2}/, '');
    }
    document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    generic.cookie('LOCALE', locale_cookie, {path: '/'});

    // make use of Drupal settings to determine path
    if (Drupal.settings.elc_common !== undefined && (lang + '-e-' + country.toUpperCase() + '_url' in Drupal.settings.elc_common) && path !== '/') {
      path = Drupal.settings.elc_common[lang + '-e-' + country.toUpperCase() + '_url'];
    }

    if (path === '/') {
      path = '';
    } else {
      path = window.location.pathname.replace(/^\/[a-z]{2}\/[a-z]{2}/, '');
    }
    var url = window.location.protocol + '//' + window.location.host + '/' + postfixes[country] + '/' + postfixes[lang] + path;

    var queryParamsArray = window.location.href.split('?');
    if (queryParamsArray[1]) {
      url = url + '?' + queryParamsArray[1];
    }

    $.cookie('OptanonConsent', null, {
      path: '/',
      domain: domain
    });
    $.cookie('OptanonAlertBoxClosed', null, {
      path: '/',
      domain: domain
    });
    window.location.href = url;
  } else if (locale_cookie !== '') { // hide the interstitial if it was opened when the user already had a valid locale. no need to make them navigate if they changed their mind about changing locale
    $('#localeOverlay').hide();
  } // however, never hide interstitial if locale is not defined, as users can't be allowed blank locale
};

jQuery.fn.cleanWhitespace = function() {
  this.contents().filter(
    function() {
      return this.nodeType === 3 && !/\S/.test(this.nodeValue);
    })
    .remove();
  return this;
};

$(document).ready(function() {
  if (site.isPerlgemPage && !$.cookie('LOCALE')) {
    var $current_country;
    var $country_li = $('.country', '#localeInterstitial');
    // Without locale cookie, perlgem page have a duplicate country radio buttons in country select popup.
    // To avoid the duplicate radio buttons, they are removed here.
    $country_li.each(function() {
      $current_country = $(this);
      $current_country.find('input[type="radio"]:not(:first)').remove();
      $current_country.find('br:not(:first)').remove();
    });
  }

  if (window.location.href.indexOf('aveda.eu') !== -1 || window.location.href.indexOf('elcdev.net') !== -1) {
    var locale = '';
    var localeMatch = document.cookie.match(new RegExp('(?:^|; )LOCALE=([^;]*)'));
    if (localeMatch && localeMatch.length !== 0) {
      locale = localeMatch[1];
    }
    if (locale !== '' && locale !== 'en_EU') {
      var localeParts = locale.toLowerCase().split('_');
      var localePrefix = '/' + localeParts[1] + '/' + localeParts[0];
      var url_param = window.location.href.split('?');
      var param_id = window.location.href.split('#');
      url_param = url_param[1] ? '?' + url_param[1] : param_id[1] ? '#' + param_id[1] : '';
      var path = window.location.pathname;
      if (path.substr(0, 6) !== localePrefix) {
        window.location.href = window.location.protocol + '//' + window.location.host + localePrefix + path.replace(/^\/[a-z]{2}\/[a-z]{2}/, '') + url_param;
      }
    }
  }
});
